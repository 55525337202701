<template>
  <div v-show="!isHidden" style="width: 100%; height: 100%">
    <grid
      ref="grid"
      :key="key"
      :columns="columns"
      :pagination="true"
      :data-source-service="dataSourceService"
      :multi-selection="multiSelection"
      :check-box-selection="checkBoxSelection"
      @grid-ready="isGridReady = true"
    ></grid>
    <slot></slot>
  </div>
</template>

<script>
import Grid from '@/core/infrastructure/components/Grid/index'
import mixin from '@/components/InterfaceEditor/components/mixins'
import VisibleMixin from '@/components/InterfaceEditor/components/visible_properties_mixin'
import { ExtendedObject } from '@/components/InterfaceEditor/components/basic/AnalyticTable/DataSources/ExtendedObject'
import { Query } from '@/components/InterfaceEditor/components/basic/AnalyticTable/DataSources/Query'

export default {
  name: 'AnalyticTable',
  components: { Grid },
  mixins: [mixin, VisibleMixin],
  inject: { isEditor: {
    default: () => false
  },
  updateComponentProperty: {
    default: () => {}
  } },
  props: {
    guid: {
      type: String,
      frozen: true
    },
    source: {
      type: Object,
      editor: 'AnalyticTableSource',
      default: () => {
        return {
          type: null,
          entityId: null
        }
      }
    },
    columns: {
      description: 'Столбцы',
      type: Array,
      default: () => [],
      editor: 'TableColumns',
      options: {
        sourceType: 'external'
      }
    },
    checkBoxSelection: {
      type: Boolean,
      description: 'Чек-бокс выделение',
      default: false
    },
    multiSelection: {
      type: Boolean,
      description: 'Мульти-выбор',
      default: false
    },
    filters: {
      type: Array,
      editor: 'Filters',
      options: {
        showXrefOption: true
      }
    },
    initialColumnState: {
      type: Array,
      frozen: true
    }
  },
  data () {
    return {
      dataSourceService: null,
      isGridReady: false,
      isComponentReady: false,
      filterDebounce: undefined
    }
  },
  beforeMount () {
    this.init()
  },
  computed: {
    dataFilters () {
      let filters = []
      if (this.filters) {
        this.filters.forEach((item) => {
          let object = {}
          let equalsType = 'eq'
          if (item.isXref) {
            equalsType = 'equals_any'
          }
          object[equalsType] = {}
          if (!item.alias) {
            return
          }
          let alias = item.alias
          if (!item.type || item.type === 'field') {
            if (this.getModel()[item.attribute]) {
              object[equalsType][alias] = this.getModel()[item.attribute]
            }
          } else if (item.type === 'constant') {
            object[equalsType][alias] = item.attribute
          } else if (item.type === 'current_user') {
            object[equalsType][alias] = this.$store.getters['Authorization/userId']
          }
          if (Object.keys(object[equalsType]).length > 0) {
            filters.push(object)
          }
        })
      }
      return filters
    },
    key () {
      return `${this.source.type}_${this.source.entityId}`
    },
    columnState () {
      if (this.isGridReady) {
        return this.$refs.grid.columnApi.getColumnState()
      }

      return []
    }
  },
  methods: {
    init () {
      if (!this.source.type || !this.source.entityId) {
        return false
      }
      switch (this.source.type) {
        case 'extended_object':
          this.dataSourceService = new ExtendedObject(this.source.entityId, this.dataFilters)
          break
        case 'query':
          this.dataSourceService = new Query(this.source.entityId, this.dataFilters)
          break
      }
    }
  },
  watch: {
    dataFilters () {
      if (this.filterDebounce) {
        clearTimeout(this.filterDebounce)
      }
      this.filterDebounce = setTimeout(() => {
        this.dataSourceService.setExternalFilters(this.dataFilters)
        this.$refs.grid.load()
      }, 300)
    },
    key () {
      this.isGridReady = false
      this.isComponentReady = false
      this.updateComponentProperty([], 'initialColumnState', this.guid)
      this.init()
    },
    isGridReady (value) {
      if (value && this.initialColumnState) {
        this.$refs.grid.columnApi.setColumnState(this.initialColumnState)
        this.isComponentReady = true
      } else if (value && (this.initialColumnState || []).length === 0) {
        this.isComponentReady = true
      }
    },
    columnState (value) {
      if (this.isEditor() && this.isComponentReady) {
        this.updateComponentProperty(value, 'initialColumnState', this.guid)
      }
    }
  }
}
</script>

<style scoped>

</style>
